import React, { useState, useEffect } from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"


import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing} from "../constants"


const ConcertHistory = (props) => {
  const [pageData, setpageData] = useState([])
  
  useEffect(() => {

    var fetchUrl = props.location.origin + "/dbinterface_frontend.php?get_past_concerts=true"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)

    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        
        //resultData = [{"id":"113","name":"6. Abonnementskonzert","slug":"6-abonnementskonzert","start_datetime":"2021-04-15 23:27:00"},{"id":"109","name":"Konzert in Vergangenheit 2","slug":"konzert-in-vergangenheit-2","start_datetime":"2021-04-01 15:00:00"},{"id":"108","name":"Konzert in Vergangenheit","slug":"konzert-in-vergangenheit","start_datetime":"2021-03-30 18:30:00"}];
        setpageData(resultData);
      })
  }, []);

  return (
    <div css={css`
      background-color: ${color.light};
      
      margin: 0;
    `}>
      <h1>Vergangene Konzerte</h1>
    {pageData ? 
    (
      <div>
        <ul css={css`list-style: none; padding: 0;`}>
        {pageData.map((concert) => 
          <li css={css`
            background-color: #F28F5F; 
            padding: ${spacing.xs}; 
            margin: ${spacing.xs} 0;
            &:hover {
              background-color: #f09f77; 
            }
          `}><Link to={`/konzerte/${concert.slug}`} css={css`text-decoration: none; color: ${color.light};`}>{concert.name}</Link></li>
        )}
        </ul>
      </div>
    ) : 
    (
      <p>Loading...</p>
    )}
    </div>
  )
}

export default ConcertHistory
